.app-header {
    border-bottom: 1px solid gray;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.app-header-logo {
    height: 44px;
}