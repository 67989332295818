.tattoo-post {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
}

.tattoo-post-bg {
    background-color: rgba(0, 0, 0, 0.803);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.tattoo-post-content {
    position: relative;
    max-width: 1200px;
    background-color: black;
}

.tattoo-post-content img {
    display: block;
    max-width: 100%;
    max-height: calc(100vh-120px);
    margin-left: auto;
    margin-right: auto;
}

.tattoo-post-content h4 {
    color: white;
    text-align: center;
}