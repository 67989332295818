body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* CSS ตกแต่ง Banner สีดำ สามารถลบออกพร้อมกับโค้ด HTML ที่ไฟล์ index.html ได้ */
.purpose-banner {
  color: rgb(230, 230, 230);
  background-color: rgb(36, 36, 36);
  font-size: 12px;
}

.purpose-banner .purpose-banner-item {
  padding: 8px;
}

.purpose-banner .purpose-banner-item:not(:first-of-type) {
  padding-top: 0px;
}

.purpose-banner a {
  color: white;
}

@media screen and (min-width: 768px) {
  .purpose-banner {
    display: flex;
    justify-content: space-between;
  }

  .purpose-banner .purpose-banner-item:not(:first-of-type) {
    padding-top: 8px;
  }
}