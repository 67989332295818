.app-search {
    margin-bottom: 15px;

}

.app-search-input {
    width: 100%;
    padding: 8px;
    font-size: 16px;
    box-sizing: border-box;
    border: 1px solid gray;

}