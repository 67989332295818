.app-section {
    padding: 30px;
}

.app-container {
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
}

.app-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 16px;
    row-gap: 16px;
}


